<template>
  <div class="box">
    <div class="box-header">
      <h4>{{ pageTitle }}</h4>
    </div>
    <model-form
      v-if="model"
      :submit-text="'保存'"
      :model="model"
      :is-edit="false"
      @on-submit="createModel"
    >
    </model-form>
    <placeholder-form v-else></placeholder-form>
  </div>
</template>

<script>
import ModelForm from './components/_Form'
import PlaceholderForm from '@core/components/Placeholder/PlaceholderForm'
import flatry from '@admin/utils/flatry'
import IntegralService from '@admin/services/IntegralService'

export default {
  name: 'IntegralShopCreate',
  data () {
    return {
      pageTitle: '创建积分商品',
      model: {
        type: 1,
        coupon: { // 优惠劵类型
          start_time: '',
          level_ids: [],
          days: 45,
          stock: 0,
          user_limit: 1,
          coupon_id: '' // 所选优惠劵id
        },
        shop: { // 商品类型
          shop_id: '',
          s_body: [
            {
              title: '',
              body: ''
            }
          ],
          s_stock: 0,
          s_cover: ''
        },
        code: { // 兑换码类型
          i_stock: 0,
          i_body: [
            {
              title: '',
              body: ''
            }
          ],
          i_cover: '',
          code_maps: []
        },
        time: [],

        // 用户商品类型
        shops: [],
        // 优惠劵等级类型
        level: [],
        // 优惠劵列表
        coupons: []
      }
    }
  },

  components: { PlaceholderForm, ModelForm },

  async created () {
    const { data } = await flatry(IntegralService.createShop())

    if (data) {
      this.model.shops = data.data.shops
      this.model.level = data.data.level
      this.model.coupons = data.data.coupons
    }
  },

  methods: {
    async createModel (model, success, callback) {
      const { data } = await flatry(IntegralService.createShop(model))

      if (data) {
        this.$message.success(data.msg)
        this.$router.replace({
          path: '/integral/shop/update',
          query: { id: data.data }
        })
        success()
      }

      callback()
    }
  }
}

</script>
<style lang='' scoped>

</style>
